import { Framework } from 'vuetify'

const utils = {

  /**
   * Mobile is defined as:
   * breakpoint name = xs OR (sm and landscape)
   *
   * if it is 'xs' we know we are portrait on mobile
   * if it's sm, it could be a portrait ipad or a lanscape mobile so we need
   * to verify that if it's sm we are in landscape
   *
   * @param v:Framework   Vuetify framework
   */
  mobile: (v: Framework): boolean => (v.breakpoint.xs || (v.breakpoint.sm && utils.landscape(v))),

  /**
   * iPad is defined as:
   * breakpoint name = sm and portrait
   *
   * @param v:Framework   Vuetify framework
   */
  ipad: (v: Framework): boolean => (v.breakpoint.sm && !utils.landscape(v)),

  /**
   * Landscape is define as:
   * width > height
   *
   * @param v:Framework   Vuetify framework
   */
  landscape: (v: Framework): boolean => (v.breakpoint.height < v.breakpoint.width),

  name: (v: Framework): string => {
    let name = 'laptop'
    if (utils.mobile(v)) name = 'mobile'
    else if (utils.ipad(v)) name = 'ipad'

    return (utils.landscape(v)) ? `${name}-landscape` : `${name}-portrait`
  },

}
export default utils
