
















import display from '@/utilities/Display'

export default {
  name: 'Test',
  computed: {
    token() {
      return localStorage.getItem('tk')
    },
    env() {
      return process.env.NODE_ENV
    },
    mobile() {
      return display.mobile(this.$vuetify)
    },
    landscape() {
      return display.landscape(this.$vuetify)
    },
    ipad() {
      return display.ipad(this.$vuetify)
    },
    displayName() {
      return display.name(this.$vuetify)
    },
  },
}
